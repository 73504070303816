import { notification } from 'apps/ui';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MerchantLink } from '../models/links.model';
import { useFormatMessage } from 'apps/intl';
import { merchantLinksCreate, merchantLinksDelete, merchantLinksLoad, merchantLinksUpdate } from 'state/merchant/merchant.actions';
import { selectMerchantLinks } from 'state/merchant/merchant.selectors';

type ParsedMerchantLink = MerchantLink & { flowId?: string };

function parse(links: MerchantLink[]): ParsedMerchantLink[] {
  return links.map((item) => {
    let flowId;
    try {
      flowId = new URL(item.originalUrl).searchParams.get('flowId');
    } catch (error) {
      console.error(error);
    }

    return {
      ...item,
      flowId,
    };
  });
}

export function useMerchantLinks() {
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();
  const links = useSelector(selectMerchantLinks);
  const [parsedLonks, setParsedLinks] = useState<ParsedMerchantLink[]>([]);

  useEffect(() => {
    setParsedLinks(parse(links));
  }, [links]);

  useEffect(() => {
    dispatch<any>(merchantLinksLoad())
      .catch(() => notification.error(formatMessage('MerchatLinks.list.error')));
  }, [dispatch]);

  const create = useCallback(async (link: ParsedMerchantLink) => {
    try {
      await dispatch<any>(merchantLinksCreate(link));
      notification.done(formatMessage('MerchatLinks.create.success'));
    } catch {
      notification.error(formatMessage('MerchatLinks.create.error'));
    }
  }, [dispatch]);

  const update = useCallback(async (link: ParsedMerchantLink) => {
    try {
      const updatedLink = { ...link };
      delete updatedLink.flowId;
      await dispatch<any>(merchantLinksUpdate(updatedLink as MerchantLink));
      notification.done(formatMessage('MerchatLinks.update.success'));
    } catch {
      notification.error(formatMessage('MerchatLinks.update.error'));
    }
  }, [dispatch]);

  const remove = useCallback(async (link) => {
    try {
      await dispatch<any>(merchantLinksDelete(link));
      notification.done(formatMessage('MerchatLinks.delete.success'));
    } catch {
      notification.error(formatMessage('MerchatLinks.delete.error'));
    }
  }, [dispatch]);

  return {
    links: parsedLonks,
    create,
    update,
    remove,
  };
}
