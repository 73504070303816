import { MerchantLink } from 'apps/Links/models/links.model';
import { IFlow } from 'models/Flow.model';
import { Loadable } from 'models/Loadable.model';
import { IHasVerificationsType, IMerchant, IMerchantApp, IMerchantConfiguration } from 'models/Merchant.model';

export const MERCHANT_STORE_KEY = 'merchant';

export const MerchantActionGroups = {
  Merchant: 'MERCHANT',
  Configuration: 'CONFIGURATION',
  App: 'APP',
  CustomDocuments: 'CUSTOM_DOCUMENTS',
  Links: 'LINKS',
  Flows: 'FLOWS',
  HasVerifications: 'HAS_VERIFICATIONS',
  BusinessName: 'BUSINESS_NAME',
  TransferOwnership: 'TRANSFER_OWNERSHIP',
  GetManageRoles: 'GET_MANAGE_ROLES',
  CreateManageRoles: 'CREATE_MANAGE_ROLES',
  UpdateManageRoles: 'UPDATE_MANAGE_ROLES',
  DeleteManageRoles: 'DELETE_MANAGE_ROLES',
};

export enum SliceNameTypes {
  Merchant = 'merchant',
  Configuration = 'configurations',
  App = 'app',
  CustomDocuments = 'customDocuments',
  Links = 'links',
  Flows = 'flows',
  HasVerifications = 'hasVerifications',
  ManageRoles = 'manageRoles',
}

export interface IMerchantStore {
  [SliceNameTypes.Merchant]: Loadable<IMerchant>;
  [SliceNameTypes.Configuration]: Loadable<IMerchantConfiguration>;
  [SliceNameTypes.App]: Loadable<IMerchantApp[]>;
  [SliceNameTypes.CustomDocuments]: Loadable<any[]>;
  links: MerchantLink[];
  [SliceNameTypes.Flows]: Loadable<IFlow[]>;
  [SliceNameTypes.HasVerifications]: Loadable<IHasVerificationsType>;
  [SliceNameTypes.ManageRoles]: Loadable<null>;
  currentFlow: string | null;
  pristineFlows: string[];
}
