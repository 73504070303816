import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import { CopyToClipboard } from 'apps/ui';
import { useFormatMessage } from 'apps/intl';
import { permalinkUrl } from 'lib/client/urls';
import { Routes } from 'models/Router.model';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { selectClientId, selectCurrentFlowId, selectMerchantTags } from 'state/merchant/merchant.selectors';
import classnames from 'classnames';
import { LimiterSubscriptionStatus, PermissionSubscriptionStatusTypes, useHasPermissionSubscription } from 'apps/Permissions';
import { useStyles } from './DirectLinkCopy.styles';
import { useMerchantLinks } from 'apps/Links';
import { MerchantTagsTypes } from 'models/Merchant.model';

export function DirectLinkCopy() {
  const formatMessage = useFormatMessage();
  const location = useLocation();
  const classes = useStyles();
  const clientId = useSelector(selectClientId);
  const flowId = useSelector(selectCurrentFlowId);
  const tags = useSelector(selectMerchantTags);
  const { links: merchantLinks, create, remove } = useMerchantLinks();
  const [isLoading, setIsLoading] = useState(false);
  const hasPermission = useHasPermissionSubscription(PermissionSubscriptionStatusTypes.canCopyDirectLink);
  const link = permalinkUrl({
    clientId: hasPermission ? clientId : '',
    flowId: hasPermission ? flowId : '',
  });
  const canUseStaticShortLinks = useMemo(() => tags.includes(MerchantTagsTypes.CanUseStaticShortLinks), [tags]);
  const shortLink = useMemo(() => merchantLinks.find((item) => item.flowId === flowId) ?? null, [merchantLinks, flowId]);

  return (
    <Grid container direction="column">
      <Typography variant="subtitle2" gutterBottom>{formatMessage('forDevs.directLinkCopy.header')}</Typography>
      <Box mb={2} color="common.black75">
        {formatMessage('forDevs.directLinkCopy.subheader')}
      </Box>
      {canUseStaticShortLinks && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gridGap="8px"
          pb={2}
        >
          <Switch
            color="primary"
            disabled={isLoading}
            checked={Boolean(shortLink)}
            onClick={async () => {
              setIsLoading(true);
              if (shortLink) {
                await remove(shortLink);
              } else {
                await create({ originalUrl: link });
              }
              setIsLoading(false);
            }}
          />
          <Typography variant="subtitle2">
            {formatMessage('forDevs.shortLink.switch')}
          </Typography>
        </Box>
      )}
      {(canUseStaticShortLinks && shortLink) ? (
        <>
          <LimiterSubscriptionStatus permission={PermissionSubscriptionStatusTypes.canCopyDirectLink}>
            <Box mb={2} className={classnames(classes.resourceUrl, { [classes.disabled]: !hasPermission })}>
              <CopyToClipboard withCopy={hasPermission} withCopyText={hasPermission} isOverlay text={shortLink.shortUrl}>
                <Box px={2} py={1.5}>
                  {shortLink.shortUrl}
                </Box>
              </CopyToClipboard>
            </Box>
          </LimiterSubscriptionStatus>
          <Box mb={2} display="flex" justifyContent={location.pathname === Routes.dev.root ? 'flex-start' : 'center'}>
            <QRCode value={shortLink.shortUrl} />
          </Box>
        </>
      ) : (
        <>
          <LimiterSubscriptionStatus permission={PermissionSubscriptionStatusTypes.canCopyDirectLink}>
            <Box mb={2} className={classnames(classes.resourceUrl, { [classes.disabled]: !hasPermission })}>
              <CopyToClipboard withCopy={hasPermission} withCopyText={hasPermission} isOverlay text={link}>
                <Box px={2} py={1.5}>
                  {link}
                </Box>
              </CopyToClipboard>
            </Box>
          </LimiterSubscriptionStatus>
          <Box mb={2} display="flex" justifyContent={location.pathname === Routes.dev.root ? 'flex-start' : 'center'}>
            <QRCode value={link} />
          </Box>
        </>
      )}
    </Grid>
  );
}
