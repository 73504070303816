import React from 'react';
import { menuStructure, TabIDTypes } from '../../models/ForDevelopers.model';
import { CreateTab } from './CreateTab';

export function TabsMenu({ onClick, selectedId }: {
  onClick: (TabID) => void;
  selectedId: TabIDTypes;
}) {
  return (
    <>
      {menuStructure.map((item) => (
        <CreateTab
          tab={item}
          onClick={onClick}
          selectedId={selectedId}
          key={item.id}
        />
      ))}
    </>
  );
}
