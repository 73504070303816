import { DateString, fromIsoPeriod } from 'lib/date';
import { selectLoadableValue, selectModelValue } from 'lib/loadable.selectors';
import { BiometricTypes } from 'models/Biometric.model';
import { IFlow, CurrentFlowIdType } from 'models/Flow.model';
import { DEFAULT_LOCALE, LanguageList, SupportedLocales } from 'models/Intl.model';
import { Loadable } from 'models/Loadable.model';
import { PasswordExpirationPolicyDurationValue } from 'models/Settings.model';
import { IMerchant, MerchantId, MerchantTagsTypes, IMerchantSettings, IAgentNotesConfig, EmailString, IMerchantApp, IMerchantConfiguration, IMerchantConfigurationDashboard, ClientIdType, IHasVerificationsType } from 'models/Merchant.model';
import { createSelector } from 'reselect';
import { IVerificationPatterns } from 'models/VerificationPatterns.model';
import { DigitalSignatureProvider } from 'models/DigitalSignature.model';
import { InputValidationCheck } from 'models/ImageValidation.model';
import { VerificationPatternsConfigType } from 'models/VerificationPatternsConfigs.model';
import { SubscriptionStatusTypes } from 'apps/SubscriptionStatus';
import { ISubscriptionStatus, ISubscriptionStatusQuota } from 'apps/Permissions';
import { IUser } from 'models/Collaborator.model';
import { MERCHANT_STORE_KEY, IMerchantStore, SliceNameTypes } from './merchant.store';
import { MerchantLink } from 'apps/Links/models/links.model';

export const selectMerchantStore = (state: { [MERCHANT_STORE_KEY]: IMerchantStore }): IMerchantStore => state[MERCHANT_STORE_KEY];

// -- merchant

export const selectMerchantModel = createSelector<[typeof selectMerchantStore], Loadable<IMerchant>>(
  selectMerchantStore,
  (store): Loadable<IMerchant> => store[SliceNameTypes.Merchant],
);

export const selectMerchantId = createSelector<[typeof selectMerchantModel], MerchantId>(
  selectMerchantModel,
  selectModelValue((merchant: IMerchant): string => merchant.id),
);

export const selectOwnerId = createSelector<[typeof selectMerchantModel], string>(
  selectMerchantModel,
  selectModelValue((merchant: IMerchant) => merchant?.owner),
);

export const selectMerchantName = createSelector<[typeof selectMerchantModel], string>(
  selectMerchantModel,
  selectModelValue((merchant) => merchant.displayName),
);

export const selectMerchantCreatedAt = createSelector<[typeof selectMerchantModel], DateString>(
  selectMerchantModel,
  selectModelValue((merchant) => merchant.createdAt),
);

export const selectMerchantBusinessName = createSelector<[typeof selectMerchantModel], string>(
  selectMerchantModel,
  selectModelValue((merchant) => merchant.businessName),
);

export const selectMerchantEmail = createSelector<[typeof selectMerchantModel], EmailString>(
  selectMerchantModel,
  selectModelValue((merchant) => merchant?.indexFields?.ownerEmail),
);

export const selectIsBlockedModel = createSelector<[typeof selectMerchantModel], Loadable<Boolean>>(
  selectMerchantModel,
  selectLoadableValue((merchant) => Boolean(merchant.blockedAt)),
);

export const selectMerchantLegalName = createSelector<[typeof selectMerchantModel], string>(
  selectMerchantModel,
  selectModelValue((merchant) => merchant.legalName),
);

export const selectMerchantBrandName = createSelector<[typeof selectMerchantModel], string>(
  selectMerchantModel,
  selectModelValue((merchant) => merchant.brandName),
);

export const selectMerchantLegalRegNumber = createSelector<[typeof selectMerchantModel], string>(
  selectMerchantModel,
  selectModelValue((merchant) => merchant.legalRegNumber),
);

export const selectMerchantLegalAddress = createSelector<[typeof selectMerchantModel], string>(
  selectMerchantModel,
  selectModelValue((merchant) => merchant.legalAddress),
);

export const selectMerchantTags = createSelector<[typeof selectMerchantModel], MerchantTagsTypes[]>(
  selectMerchantModel,
  selectModelValue((merchant: IMerchant): MerchantTagsTypes[] => merchant.tags || []),
);

export const selectUserCountry = createSelector(
  selectMerchantModel,
  selectModelValue((merchant) => merchant?.countryCodes),
);

export const selectCanMerchantUseReusage = createSelector<[typeof selectMerchantTags], boolean>(
  selectMerchantTags,
  (tags) => tags.includes(MerchantTagsTypes.CanUseReusage) && tags.includes(MerchantTagsTypes.CanUseVerificationReusage),
);

// -- app

const selectAppModel = createSelector<[typeof selectMerchantStore], Loadable<IMerchantApp[]>>(
  selectMerchantStore,
  (merchant) => merchant[SliceNameTypes.App],
);

export const selectAppLastModel = createSelector<[typeof selectAppModel], Loadable<IMerchantApp>>(
  selectAppModel,
  selectLoadableValue((app) => app.slice(-1).pop() || {}),
);

export const selectClientIdModel = createSelector<[typeof selectAppLastModel], Loadable<ClientIdType>>(
  selectAppLastModel,
  selectLoadableValue((app) => app.clientId),
);

export const selectClientId = createSelector<[typeof selectClientIdModel], ClientIdType>(
  selectClientIdModel,
  selectModelValue(),
);

// -- settings

export const selectMerchantSettings = createSelector<[typeof selectMerchantModel], IMerchantSettings>(
  selectMerchantModel,
  selectModelValue((merchant) => merchant.settings),
);

export const selectMerchantAgentNotesConfig = createSelector<[typeof selectMerchantSettings], IAgentNotesConfig>(
  selectMerchantSettings,
  (settings) => settings?.agentNotesConfig,
);

export const selectMerchantPasswordExpirationPolicy = createSelector<[typeof selectMerchantModel], PasswordExpirationPolicyDurationValue>(
  selectMerchantModel,
  selectModelValue((merchant) => merchant?.passwordExpirationPolicy),
);

// -- configuration

export const selectConfigurationModel = createSelector<[typeof selectMerchantStore], Loadable<IMerchantConfiguration>>(
  selectMerchantStore,
  (store) => store[SliceNameTypes.Configuration],
);

export const selectPatternsConfigValue = createSelector<[typeof selectConfigurationModel], VerificationPatternsConfigType>(
  selectConfigurationModel,
  selectModelValue((cfg) => cfg.patternsConfig),
);
// -- dashboard

export const selectDashboardModel = createSelector<[typeof selectConfigurationModel], Loadable<IMerchantConfigurationDashboard>>(
  selectConfigurationModel,
  selectLoadableValue((cfg) => cfg.dashboard),
);

// TODO @dkchv: move to intl feature
export const selectLanguage = createSelector<[typeof selectDashboardModel], SupportedLocales>(
  selectDashboardModel,
  selectModelValue((dashboard) => {
    const locale = dashboard.language;
    const isSupported = !!LanguageList.find((item) => item.locale === locale);
    if (!isSupported) {
      console.warn(`warn: Locale ${locale} not found`);
      return DEFAULT_LOCALE;
    }
    return locale;
  }),
);

// custom documents

export const selectMerchantCustomDocumentsModel = createSelector<[typeof selectMerchantStore], Loadable<any>>(
  selectMerchantStore,
  (merchant) => merchant[SliceNameTypes.CustomDocuments],
);

export const selectMerchantCustomDocumentsError = createSelector<[typeof selectMerchantCustomDocumentsModel], string>(
  selectMerchantCustomDocumentsModel,
  (customDocumentModel) => customDocumentModel.error,
);

// -- links

export const selectMerchantLinks = createSelector<[typeof selectMerchantStore], MerchantLink[]>(
  selectMerchantStore,
  (merchant) => merchant.links,
);

// -- flows

export const selectMerchantFlowsModel = createSelector<[typeof selectMerchantStore], Loadable<IFlow[]>>(
  selectMerchantStore,
  (merchant) => merchant[SliceNameTypes.Flows],
);

export const selectMerchantFlowList = createSelector<[typeof selectMerchantFlowsModel], IFlow[]>(
  selectMerchantFlowsModel,
  (model): IFlow[] => model.value || [],
);

export const selectCurrentFlowId = createSelector<[typeof selectMerchantStore], CurrentFlowIdType>(
  selectMerchantStore,
  (store) => store.currentFlow,
);

export const selectPristineFlowIds = createSelector<[typeof selectMerchantStore], string[]>(
  selectMerchantStore,
  (store) => store.pristineFlows,
);

export const selectCurrentFlow = createSelector<[typeof selectMerchantFlowsModel, typeof selectCurrentFlowId], IFlow>(
  selectMerchantFlowsModel,
  selectCurrentFlowId,
  selectModelValue((model, id) => model.find((item) => item.id === id)),
);

export const selectSupportedCountries = createSelector<[typeof selectCurrentFlow], string[]>(
  selectCurrentFlow,
  (flow) => flow.supportedCountries || [],
);

export const selectUploadDenial = createSelector<[typeof selectCurrentFlow], boolean>(
  selectCurrentFlow,
  (flow) => flow.denyUploadsFromMobileGallery,
);

export const selectPolicyInterval = createSelector<[typeof selectCurrentFlow], string>(
  selectCurrentFlow,
  (flow) => fromIsoPeriod(flow.policyInterval),
);

export const selectVerificationPattern = createSelector<[typeof selectCurrentFlow], IVerificationPatterns>(
  selectCurrentFlow,
  (flow) => flow.verificationPatterns,
);

export const selectBiometricPattern = createSelector<[typeof selectVerificationPattern], string>(
  selectVerificationPattern,
  (pattern) => pattern.biometrics || BiometricTypes.none,
);

export const selectIsVoiceLiveness = createSelector<[typeof selectBiometricPattern], boolean>(
  selectBiometricPattern,
  (pattern) => pattern === BiometricTypes.voiceLiveness,
);

export const selectNom151Check = createSelector<[typeof selectCurrentFlow], DigitalSignatureProvider>(
  selectCurrentFlow,
  (flow) => flow.digitalSignature,
);

export const selectLogoModel = createSelector(
  selectCurrentFlow,
  (flow) => flow.logo || {},
);

export const selectValidationChecks = createSelector<[typeof selectCurrentFlow], InputValidationCheck[]>(
  selectCurrentFlow,
  (flow) => flow.inputValidationChecks || [],
);

export const selectPostponedTimeout = createSelector<[typeof selectCurrentFlow], string>(
  selectCurrentFlow,
  (flow) => flow.postponedTimeout,
);

export const selectPhoneRiskAnalysisThreshold = createSelector<[typeof selectCurrentFlow], number>(
  selectCurrentFlow,
  (flow) => flow.phoneRiskAnalysisThreshold,
);

export const selectMerchantSubscriptionStatus = createSelector<[typeof selectMerchantModel], ISubscriptionStatus>(
  selectMerchantModel,
  selectModelValue((merchant) => merchant.subscriptionStatus),
);

export const selectMerchantSubscriptionStatusValue = createSelector<[typeof selectMerchantSubscriptionStatus], SubscriptionStatusTypes>(
  selectMerchantSubscriptionStatus,
  (subscriptionStatus) => subscriptionStatus?.value,
);

export const selectMerchantTrialQuota = createSelector<[typeof selectMerchantModel], ISubscriptionStatusQuota>(
  selectMerchantModel,
  selectModelValue((merchant) => merchant.quota),
);

export const selectHasVerificationsModel = createSelector<[typeof selectMerchantStore], Loadable<IHasVerificationsType>>(
  selectMerchantStore,
  (store) => store[SliceNameTypes.HasVerifications],
);

export const selectManageRolesModel = createSelector<[typeof selectMerchantStore], Loadable<any>>(
  selectMerchantStore,
  (merchant) => merchant[SliceNameTypes.ManageRoles],
);

// user
export const selectUser = createSelector<[typeof selectMerchantModel], IUser>(
  selectMerchantModel,
  selectModelValue((merchant) => merchant.user),
);

export const selectUserCreatedAt = createSelector(
  selectUser,
  (user) => user && user.dateCreated,
);

export const selectUserEmail = createSelector(
  [selectUser],
  (user) => user && user.email,
);

export const selectUserRole = createSelector(
  [selectUser],
  (user) => user && user.userRole,
);

export const selectUserName = createSelector(
  [selectUser],
  (user) => user && `${user.firstName} ${user.lastName}`,
);

export const selectCanUseADFraudScore = createSelector<[typeof selectMerchantTags], boolean>(selectMerchantTags, (tags) => tags.includes(MerchantTagsTypes.CanUseAlterationDetectionFraudScore));

