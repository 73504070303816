import { appPalette } from 'apps/theme';
import { Grid, Typography, TextareaAutosize, TextField } from '@material-ui/core';
import { inputCustomDocumentValidationChecksDefaultValue } from 'models/ImageValidation.model';
import cloneDeep from 'lodash/cloneDeep';
import { CustomDocumentResponse } from 'models/CustomDocument.model';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from 'apps/intl';
import { selectMerchantCustomDocumentsError } from 'state/merchant/merchant.selectors';
import { useStyles } from './CustomDocumentBasicInfo.styles';
import { CustomDocumentWizadFooter } from '../CustomDocumentWizadFooter/CustomDocumentWizadFooter';
import { CustomDocumentPageTypes, CustomDocumentWizardStepTypes, CUSTOM_DOCUMENT_PREFIX, getCustomDocumentBasicInfoValidation, getNumberOfPages, TEXT_DETECTION_RELEASE } from '../../models/CustomDocument.model';
import { updateCustomDocumentModal, updateCustomDocumentWizardStep } from '../../state/customDocument.actions';
import { selectCustomDocumentModal } from '../../state/customDocument.selectors';
import { CustomDocumentBasicInfoSelect } from '../CustomDocumentBasicInfoSelect/CustomDocumentBasicInfoSelect';
import { BoxBordered, Warning, WarningSize, WarningTypes } from 'apps/ui';

export function CustomDocumentBasicInfo({ editable }: { editable: boolean }) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const dispatch = useDispatch();
  const customDocument = useSelector<any, Partial<CustomDocumentResponse>>(selectCustomDocumentModal);
  const customDocumentsError = useSelector<any, string>(selectMerchantCustomDocumentsError);
  const numberOfPages = getNumberOfPages(customDocument);

  const invalidData = useMemo(() => getCustomDocumentBasicInfoValidation(customDocument), [customDocument]);
  const canMoveForward = useMemo(() => Object.values(invalidData).indexOf(true) === -1, [invalidData]);

  const handleUpdateName = useCallback(({ target: { value } }) => {
    dispatch(updateCustomDocumentModal({ name: value || null }));
  }, [dispatch]);

  const handleUpdateType = useCallback(({ target: { value } }) => {
    if (!(value as string).startsWith(CUSTOM_DOCUMENT_PREFIX) || ((value as string).length < 7)) {
      dispatch(updateCustomDocumentModal({ type: CUSTOM_DOCUMENT_PREFIX }));
      return;
    }

    dispatch(updateCustomDocumentModal({ type: value || null }));
  }, [dispatch]);

  const handleUpdateDescription = useCallback(({ target: { value } }) => {
    dispatch(updateCustomDocumentModal({ description: value || null }));
  }, [dispatch]);

  const handleUpdateNumberOfPages = (value: CustomDocumentPageTypes) => {
    if (value === CustomDocumentPageTypes.Many) {
      dispatch(updateCustomDocumentModal({
        pages: 0,
        isSingleFile: true,
        example: null,
        inputValidationChecks: TEXT_DETECTION_RELEASE ? cloneDeep(inputCustomDocumentValidationChecksDefaultValue) : undefined,
      }));
      return;
    }

    if (value === CustomDocumentPageTypes.Two) {
      dispatch(updateCustomDocumentModal({
        pages: 2,
        isSingleFile: false,
        example: null,
      }));
      return;
    }

    dispatch(updateCustomDocumentModal({
      pages: 1,
      isSingleFile: false,
      example: null,
    }));
  };

  const onDone = useCallback(() => {
    dispatch(updateCustomDocumentWizardStep(CustomDocumentWizardStepTypes.MediaUpload));
  }, [dispatch]);

  return (
    <>
      <Typography variant="h3" className={classes.title}>
        {formatMessage('CustomDocuments.settings.title')}
      </Typography>

      {customDocument.reviewComment && (
        <BoxBordered borderColor={appPalette.yellow} mb={2.4}>
          <Warning
            type={WarningTypes.Warning}
            size={WarningSize.Large}
            label={customDocument.reviewComment}
          />
        </BoxBordered>
      )}

      <Grid container spacing={3} className={classes.contentHolder}>
        <Grid item xs={6}>
          <Typography variant="subtitle2" className={classes.subtitle}>
            {formatMessage('CustomDocuments.settings.documentName')}
          </Typography>
          <Typography variant="body1" className={classes.helpText}>
            {formatMessage('CustomDocuments.settings.documentName.subtitle')}
          </Typography>

          <TextField
            disabled={!editable}
            variant="outlined"
            onChange={handleUpdateName}
            type="text"
            value={customDocument?.name || ''}
            className={classes.input}
            placeholder={formatMessage('CustomDocuments.settings.documentName.placeholder')}
          />
          {(customDocument?.name?.length > 0 && invalidData.name) && <div className={classes.error}>{formatMessage('CustomDocuments.settings.documentName.error')}</div>}
        </Grid>

        <Grid item xs={6}>
          <Typography variant="subtitle2" className={classes.subtitle}>
            {formatMessage('CustomDocuments.settings.numberOfPages')}
          </Typography>
          <Typography variant="body1" className={classes.helpText}>
            {formatMessage('CustomDocuments.settings.numberOfPages.subtitle')}
          </Typography>
          <CustomDocumentBasicInfoSelect
            disabled={!editable}
            numberOfPages={numberOfPages}
            handleUpdateNumberOfPages={handleUpdateNumberOfPages}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography variant="subtitle2" className={classes.subtitle}>
            {formatMessage('CustomDocuments.settings.userDescription')}
          </Typography>
          <Typography variant="body1" className={classes.helpText}>
            {formatMessage('CustomDocuments.settings.userDescription.subtitle')}
          </Typography>
          <TextareaAutosize
            disabled={!editable}
            onChange={handleUpdateDescription}
            value={customDocument?.description || ''}
            rowsMax={3}
            rowsMin={3}
            className={classes.textarea}
            placeholder={formatMessage('CustomDocuments.settings.userDescription.placeholder')}
          />
          {(customDocument?.description?.length > 0 && invalidData.description) && <div className={classes.error}>{formatMessage('CustomDocuments.settings.userDescription.error')}</div>}
        </Grid>

        <Grid item xs={6}>
          <Typography variant="subtitle2" className={classes.subtitle}>
            {formatMessage('CustomDocuments.settings.variableName')}
          </Typography>
          <Typography variant="body1" className={classes.helpText}>
            {formatMessage('CustomDocuments.settings.variableName.subtitle')}
          </Typography>

          <TextField
            variant="outlined"
            onChange={handleUpdateType}
            value={customDocument?.type || ''}
            type="text"
            className={classes.input}
            disabled={!editable}
            placeholder={formatMessage('CustomDocuments.settings.variableName.placeholder')}
          />
          {(customDocument?.type?.replace(CUSTOM_DOCUMENT_PREFIX, '').length > 0 && invalidData.type) && (
            <div className={classes.error}>{formatMessage('CustomDocuments.settings.variableName.error')}</div>
          )}
          {customDocumentsError && (
            <div className={classes.error}>{formatMessage(`CustomDocuments.settings.variableName.error.${customDocumentsError}`)}</div>
          )}
        </Grid>
      </Grid>

      <CustomDocumentWizadFooter
        onForward={onDone}
        canMoveForward={canMoveForward}
        forwardTitle={formatMessage(`CustomDocuments.settings.${editable ? 'continue' : 'next'}`)}
      />

    </>
  );
}
