import { http } from 'lib/client/http';

export function saveConfiguration(configurations) {
  return http.patch('/api/v1/merchants/me', { configurations });
}

export function getMerchant() {
  return http.get('/api/v1/merchants/me');
}

export function getMerchantApps() {
  return http.get('/api/v1/merchants/apps');
}

export function uploadMerchantMedia(form) {
  return http.post('/api/v1/media', form);
}

export function saveBusinessName(businessName) {
  return http.patch('/api/v1/merchants/me', { businessName });
}

export function saveSettings(settings) {
  return http.patch('/api/v1/merchants/me', { settings });
}

export function getMerchantCustomDocuments(merchantId) {
  return http.get(`/api/v1/merchants/${merchantId}/custom-documents`);
}

export function createMerchantCustomDocument(merchantId, data) {
  return http.post(`/api/v1/merchants/${merchantId}/custom-documents`, data);
}

export function updateCustomDocument(merchantId, type, data) {
  return http.patch(`/api/v1/merchants/${merchantId}/custom-documents/${type}`, data);
}

export function deleteCustomDocument(merchantId, type) {
  return http.delete(`/api/v1/merchants/${merchantId}/custom-documents/${type}`);
}

// flows api
export function getMerchantFlows(merchantId, params) {
  return http.get(`/api/v1/merchants/${merchantId}/flows`, { params });
}

export function createMerchantFlow(merchantId, data) {
  return http.post(`/api/v1/merchants/${merchantId}/flows`, data);
}

export function updateMerchantFlow(merchantId, id, data) {
  return http.patch(`/api/v1/merchants/${merchantId}/flows/${id}`, data);
}

export function deleteMerchantFlow(merchantId, id) {
  return http.delete(`/api/v1/merchants/${merchantId}/flows/${id}`);
}

export function getVerificationsSummary() {
  return http.get('/api/v1/dashboard/merchant/verificationsSummary');
}

export function transferOwnership(merchantId) {
  return http.post('/api/v1/dashboard/merchant/transfer-ownership', { newOwnerId: merchantId });
}

export function getCustomRoles(params) {
  return http.get('/dashboard-gateway/merchant/roles', {
    params,
  });
}

export function createCustomRoles(payload) {
  return http.post('/dashboard-gateway/merchant/roles', { ...payload });
}

export function updateCustomRoles(payload) {
  const { _id: roleId } = payload;
  /* eslint-disable no-param-reassign */
  delete payload._id;
  /* eslint-enable no-param-reassign */
  return http.patch(`/dashboard-gateway/merchant/roles/${roleId}`, { ...payload });
}

export function deleteCustomRoles(roleId) {
  return http.delete(`/dashboard-gateway/merchant/roles/${roleId}`);
}

export function getLinks(merchantId) {
  return http.get(`/api/v1/merchants/${merchantId}/links`);
}

export function createLink(merchantId, link) {
  return http.post(`/api/v1/merchants/${merchantId}/links`, link);
}

export function updateLink(merchantId, link) {
  return http.put(`/api/v1/merchants/${merchantId}/links/${link.linkId}`, link);
}

export function deleteLink(merchantId, link) {
  return http.delete(`/api/v1/merchants/${merchantId}/links/${link.linkId}`);
}
