import { Grid, Typography, Button, TextareaAutosize, Select, MenuItem } from '@material-ui/core';
import { isNil } from 'lib/isNil';
import React, { useCallback, useMemo, useRef } from 'react';
import { FiPlus, FiEdit2, FiTrash2, FiChevronDown } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from 'apps/intl';
import { ICustomDocumentReadingField, CustomDocumentVerificationFlowFieldTypes } from 'models/CustomDocument.model';
import { TextFieldInput, useStyles } from './CustomDocumentFieldSettings.styles';
import { CustomDocumentWizadFooter } from '../CustomDocumentWizadFooter/CustomDocumentWizadFooter';
import { updateCustomDocumentDocumentReadingField, editCustomDocumentDocumentReadingField, updateCustomDocumentWizardStep, updateCustomDocumentDocumentReadingEditedFieldOption, resetCustomDocumentDocumentReadingFieldOption, updateCustomDocumentDocumentReadingFieldOption } from '../../state/customDocument.actions';
import { CustomDocumentWizardStepTypes, NOT_UNIQUE_TYPE_ERROR } from '../../models/CustomDocument.model';
import { selectCustomDocumentDocumentReadingFieldSettings, selectCustomDocumentEditedField, selectCustomDocumentReadingFields } from '../../state/customDocument.selectors';

export function CustomDocumentFieldSettings({ editable }: {
  editable: boolean
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const dispatch = useDispatch();
  const field = useSelector<any, ICustomDocumentReadingField>(selectCustomDocumentDocumentReadingFieldSettings);
  const fields = useSelector<any, ICustomDocumentReadingField[]>(selectCustomDocumentReadingFields);
  const edited = useSelector<any, Nullable<number>>(selectCustomDocumentEditedField);
  const initialWebhookId = useRef<string>(field.id);
  const isValid = useMemo<boolean>(() => {
    if (field?.type === CustomDocumentVerificationFlowFieldTypes.Options && field?.options?.length < 1) {
      return false;
    }

    return (field?.id?.length > 0) && (field?.inputFormat?.length > 0) && !isNil(field?.type) && (field?.label?.length > 0);
  }, [field]);

  const webhookLabelErrorCode = useMemo<string>(() => {
    if (fields.some((currentField) => (currentField.id === field.id) && (field.id !== initialWebhookId.current))) {
      return NOT_UNIQUE_TYPE_ERROR;
    }

    return '';
  }, [field.id, fields, initialWebhookId]);

  const handleLabelUpdate = useCallback(({ target: { value } }) => {
    dispatch(updateCustomDocumentDocumentReadingField({ label: value || null }));
  }, [dispatch]);

  const handleTypeUpdate = useCallback(({ target: { value } }) => {
    dispatch(updateCustomDocumentDocumentReadingField({
      type: value || null,
      options: [],
    }));
  }, [dispatch]);

  const handleInputFormatUpdate = useCallback(({ target: { value } }) => {
    dispatch(updateCustomDocumentDocumentReadingField({ inputFormat: value || null }));
  }, [dispatch]);

  const handleIdUpdate = useCallback(({ target: { value } }) => {
    dispatch(updateCustomDocumentDocumentReadingField({ id: value || null }));
  }, [dispatch]);

  const handleEditOption = useCallback((index: number, option: string) => () => {
    dispatch(updateCustomDocumentDocumentReadingEditedFieldOption(index));
    if (isNil(index)) {
      dispatch(resetCustomDocumentDocumentReadingFieldOption());
    } else {
      dispatch(updateCustomDocumentDocumentReadingFieldOption(option));
    }
    dispatch(updateCustomDocumentWizardStep(CustomDocumentWizardStepTypes.DocumentReadingFieldOptionSettings));
  }, [dispatch]);

  const handleDeleteOption = useCallback((index: number) => () => {
    const updatedOptions = [...field.options];
    updatedOptions.splice(index, 1);
    dispatch(updateCustomDocumentDocumentReadingField({ options: updatedOptions }));
  }, [field, dispatch]);

  const handleDone = useCallback(() => {
    if (editable) {
      return;
    }

    if (webhookLabelErrorCode) {
      return;
    }

    dispatch(editCustomDocumentDocumentReadingField(edited, field));
    dispatch(updateCustomDocumentWizardStep(CustomDocumentWizardStepTypes.DocumentReadingSettings));
  }, [webhookLabelErrorCode, edited, field, dispatch]);

  const handleBack = useCallback(() => {
    dispatch(updateCustomDocumentWizardStep(CustomDocumentWizardStepTypes.DocumentReadingSettings));
  }, [dispatch]);

  return (
    <>
      <Typography variant="h3" className={classes.title}>
        {formatMessage('CustomDocuments.settings.addFieldForYourDocument')}
      </Typography>

      <Grid container spacing={3} className={classes.contentHolder}>
        <Grid item xs={6}>
          <Typography variant="subtitle2" className={classes.subtitle}>
            {formatMessage('CustomDocuments.settings.parameterName')}
          </Typography>
          <Typography variant="body1" className={classes.helpText}>
            {formatMessage('CustomDocuments.settings.parameterName.subtitle')}
          </Typography>

          <TextFieldInput
            type="text"
            disabled={!editable}
            onChange={handleLabelUpdate}
            value={field?.label || ''}
            className={classes.input}
            placeholder={formatMessage('CustomDocuments.settings.parameterName.placeholder')}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography variant="subtitle2" className={classes.subtitle}>
            {formatMessage('CustomDocuments.settings.validationType')}
          </Typography>
          <Typography variant="body1" className={classes.helpText}>
            {formatMessage('CustomDocuments.settings.validationType.subtitle')}
          </Typography>
          <Select
            disabled={!editable}
            className={classes.select}
            disableUnderline
            onChange={handleTypeUpdate}
            value={field?.type || CustomDocumentVerificationFlowFieldTypes.Text}
            IconComponent={FiChevronDown}
          >
            <MenuItem value={CustomDocumentVerificationFlowFieldTypes.Text}>
              {formatMessage('CustomDocuments.settings.validationType.text')}
            </MenuItem>
            <MenuItem value={CustomDocumentVerificationFlowFieldTypes.Date}>
              {formatMessage('CustomDocuments.settings.validationType.dateSelection')}
            </MenuItem>
            <MenuItem value={CustomDocumentVerificationFlowFieldTypes.Options}>
              {formatMessage('CustomDocuments.settings.validationType.optionSelection')}
            </MenuItem>
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="subtitle2" className={classes.subtitle}>
            {formatMessage('CustomDocuments.settings.inputFormat')}
          </Typography>
          <Typography variant="body1" className={classes.helpText}>
            {formatMessage('CustomDocuments.settings.inputFormat.subtitle')}
          </Typography>

          <TextareaAutosize
            disabled={!editable}
            rowsMax={4}
            rowsMin={4}
            onChange={handleInputFormatUpdate}
            value={field?.inputFormat || ''}
            className={classes.textarea}
            placeholder={formatMessage('CustomDocuments.settings.inputFormat.placeholder')}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography variant="subtitle2" className={classes.subtitle}>
            {formatMessage('CustomDocuments.settings.fieldVariableName')}
          </Typography>
          <Typography variant="body1" className={classes.helpText}>
            {formatMessage('CustomDocuments.settings.variableName.subtitle')}
          </Typography>

          <TextFieldInput
            disabled={!editable}
            type="text"
            onChange={handleIdUpdate}
            value={field?.id || ''}
            className={classes.input}
            placeholder={formatMessage('CustomDocuments.settings.variableName.placeholder')}
            helperText={webhookLabelErrorCode && formatMessage(`CustomDocuments.settings.variableName.error.${webhookLabelErrorCode}`)}
            error={Boolean(webhookLabelErrorCode)}
          />
        </Grid>
      </Grid>

      {(field?.type === CustomDocumentVerificationFlowFieldTypes.Options) && (
        <>
          <div className={classes.settingRow}>
            <Typography variant="subtitle2" className={classes.secondCaption}>
              {formatMessage('CustomDocuments.settings.options')}
            </Typography>
            {editable && (
              <Button className={classes.addButton} onClick={handleEditOption(null, null)}>
                <FiPlus className={classes.addIcon} />
                {formatMessage('CustomDocuments.settings.add')}
              </Button>
            )}
          </div>
          <Grid container spacing={0} className={classes.optionsHolder}>
            {field.options?.map((option, index) => (
              <Grid item xs={6} key={option}>
                <div className={classes.tag}>
                  {option}
                  {editable && (
                    <div className={classes.buttonsHolder}>
                      <Button className={classes.editButton} onClick={handleEditOption(index, option)}>
                        <FiEdit2 />
                      </Button>
                      <Button className={classes.deleteButton} onClick={handleDeleteOption(index)}>
                        <FiTrash2 />
                      </Button>
                    </div>
                  )}
                </div>
              </Grid>
            ))}
          </Grid>
        </>
      )}

      <CustomDocumentWizadFooter
        onBack={handleBack}
        onForward={handleDone}
        canMoveForward={isValid}
        backTitle={formatMessage('CustomDocuments.settings.back')}
        forwardTitle={editable ? (isNil(edited) ? formatMessage('CustomDocuments.settings.add') : formatMessage('CustomDocuments.settings.done')) : undefined}
        disabled={Boolean(webhookLabelErrorCode)}
      />
    </>
  );
}
