import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import React, { useEffect, useState, useMemo } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { MerchantLink } from '../../models/links.model';
import { useSelector } from 'react-redux';
import { useFormatMessage } from 'apps/intl';
import { Button, Icon } from 'lib/UI';
import { Modal, useOverlay } from 'apps/overlay';
import { permalinkUrl } from 'lib/client/urls';
import { selectClientId, selectMerchantFlowsModel } from 'state/merchant/merchant.selectors';
import { useStyles } from './MerchantLinks.styles';
import { CopyToClipboard, TextFieldName } from 'apps/ui';
import { useMerchantLinks } from '../../hooks/useMerchantLinks';
import { IFlow } from 'models/Flow.model';

function LinkModal({ data = { originalUrl: '' }, onClose, onConfirm }: {
  data?: MerchantLink;
  onClose: () => void;
  onConfirm: (data: MerchantLink) => void | Promise<void>;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const [flowId, setFlowId] = useState<Nullable<string>>(null);
  const [extraParams, setExtraParams] = useState<Nullable<string>>(null);
  const { links } = useMerchantLinks();
  const merchantFlowList = useSelector(selectMerchantFlowsModel);
  const clientId = useSelector(selectClientId);
  const originalUrl = permalinkUrl({ clientId, flowId });

  const availableFlows = useMemo<IFlow[]>(() => {
    if (merchantFlowList.isLoading || merchantFlowList.isFailed || merchantFlowList.error) {
      return [];
    }

    if (links.length === 0) {
      return merchantFlowList.value;
    }

    const usedFlowIds = links.map((item) => item.flowId);
    return merchantFlowList.value.filter((item) => !usedFlowIds.includes(item.id));
  }, [links, merchantFlowList.value]);

  useEffect(() => {
    try {
      if (data.originalUrl.length > 0) {
        const url = new URL(data.originalUrl);
        const flowIdFromUrl = url.searchParams.get('flowId') || null;
        setFlowId(flowIdFromUrl);
        const extraParamsFromUrl = data.originalUrl.replace(permalinkUrl({ clientId, flowId: flowIdFromUrl }), '');
        setExtraParams(extraParamsFromUrl.startsWith('&') ? extraParamsFromUrl.replace('&', '') : extraParamsFromUrl);
      }
    } catch (error) {
      console.error(error);
    }
  }, [data, clientId]);

  return (
    <Modal style={{ width: '40vw' }}>
      <Box>
        <Typography
          variant="subtitle2"
          gutterBottom
        >
          {data.linkId ? formatMessage('MerchatLinks.update.title') : formatMessage('MerchatLinks.create.title')}
        </Typography>
      </Box>
      <Box mt={1}>
        <Box mb={0.5}>
          <Typography variant="caption" color="textSecondary">
            {formatMessage('MerchatLinks.create.flow.label')}
          </Typography>
        </Box>
        <FormControl variant="outlined" fullWidth>
          <Select
            onChange={({ target: { value } }) => setFlowId(value as string)}
            IconComponent={FiChevronDown}
            className={classes.select}
            value={flowId || ''}
          >
            {availableFlows.map((item) => (
              <MenuItem
                key={item.id}
                value={item.id}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box mt={1} width="100%">
        <Box mb={0.5}>
          <Typography variant="caption" color="textSecondary">
            {formatMessage('MerchatLinks.create.extra.label')}
          </Typography>
        </Box>
        <TextFieldName
          style={{ width: '100%' }}
          value={extraParams || ''}
          onChange={({ target: { value } }) => setExtraParams((value.length > 0) ? value : null)}
        />
      </Box>
      <Box
        mt={2}
        display="flex"
        justifyContent="flex-end"
        gridGap={2}
      >
        <Button
          type="secondary"
          destructive
          onClick={onClose}
        >
          {data.linkId ? formatMessage('MerchatLinks.update.button.cancel') : formatMessage('MerchatLinks.create.button.cancel')}
        </Button>
        <Button
          type="secondary"
          disabled={!flowId}
          onClick={() => onConfirm({
            ...data,
            originalUrl: [originalUrl, extraParams].filter(Boolean).join('&'),
          })}
        >
          {data.linkId ? formatMessage('MerchatLinks.update.button.confirm') : formatMessage('MerchatLinks.create.button.confirm')}
        </Button>
      </Box>
    </Modal>
  );
}

function ConfirmDeleteionModal({ onClose, onConfirm }: {
  onClose: () => void;
  onConfirm: () => void | Promise<void>;
}) {
  const formatMessage = useFormatMessage();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal style={{ height: '96px' }}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
      >
        <Box>
          <Typography
            variant="subtitle2"
            gutterBottom
          >
            {formatMessage('MerchatLinks.delete.title')}
          </Typography>
        </Box>
        <Box mt={1} flexGrow={1}>
          <Typography variant="body1" color="textSecondary">
            {formatMessage('MerchatLinks.delete.message')}
          </Typography>
        </Box>
        <Box
          mt={2}
          display="flex"
          justifyContent="flex-end"
          gridGap={2}
        >
          <Button
            type="secondary"
            destructive
            onClick={onClose}
          >
            {formatMessage('MerchatLinks.delete.button.cancel')}
          </Button>
          <Button
            type="secondary"
            disabled={isLoading}
            onClick={async () => {
              setIsLoading(true);
              await onConfirm();
              setIsLoading(false);
            }}
          >
            {formatMessage('MerchatLinks.delete.button.confirm')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export function MerchatLinks() {
  const formatMessage = useFormatMessage();
  const { links, create, update, remove } = useMerchantLinks();
  const merchantFlowList = useSelector(selectMerchantFlowsModel);
  const [createOverlay, closeOverlay] = useOverlay();

  const handleCreateLink = () => createOverlay(
    <LinkModal
      onClose={closeOverlay}
      onConfirm={(newLink) => create(newLink).then(closeOverlay)}
    />,
  );

  const handleEditLink = (link: MerchantLink) => createOverlay(
    <LinkModal
      data={link}
      onClose={closeOverlay}
      onConfirm={(newLink) => update(newLink).then(closeOverlay)}
    />,
  );

  const handleDelete = (link: MerchantLink) => createOverlay(
    <ConfirmDeleteionModal
      onClose={closeOverlay}
      onConfirm={() => remove(link).then(closeOverlay)}
    />,
  );

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Typography
          variant="subtitle2"
          gutterBottom
        >
          {formatMessage('MerchatLinks.list.title')}
        </Typography>
        <Box>
          <Button
            type="secondary"
            onClick={handleCreateLink}
          >
            {formatMessage('MerchatLinks.list.button.create')}
          </Button>
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1">
                  {formatMessage('MerchatLinks.list.header.flow')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  {formatMessage('MerchatLinks.list.header.url')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  {formatMessage('MerchatLinks.list.header.shortUrl')}
                </Typography>
              </TableCell>
              <TableCell />
            </TableRow>
            {(links.length > 0) ? links.map((link) => {
              const flow = merchantFlowList.value.find((item) => item.id === link.flowId);
              return (
                <TableRow>
                  <TableCell>
                    {flow.name}
                  </TableCell>
                  <TableCell>
                    <CopyToClipboard text={link.originalUrl}>
                      <Box style={{ wordBreak: 'break-all', maxWidth: 300 }}>
                        {link.originalUrl}
                      </Box>
                    </CopyToClipboard>
                  </TableCell>
                  <TableCell>
                    <CopyToClipboard text={link.shortUrl}>
                      <Box style={{ wordBreak: 'break-all', maxWidth: 300 }}>
                        {link.shortUrl}
                      </Box>
                    </CopyToClipboard>
                  </TableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      gridGap={4}
                    >
                      <Button
                        type="icon"
                        destructive
                        onClick={() => handleDelete(link)}
                      >
                        <Icon name="delete" />
                      </Button>
                      <Button
                        type="icon"
                        onClick={() => handleEditLink(link)}
                      >
                        <Icon name="edit" />
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            }) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography variant="caption" color="textSecondary">
                    {formatMessage('MerchatLinks.list.empty')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
