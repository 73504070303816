import { InputValidationCheck } from './ImageValidation.model';
import { VerificationPatternTypes } from './VerificationPatterns.model';
import { DocumentSideTypes } from './Document.model';
import { Media } from './Media.model';

export const MAX_CUSTOMDOC_QTY = 20;
export const customDocumentListLimit = 10;

export enum CustomDocumentVerificationFlowFieldTypes {
  Text = 'Text',
  Date = 'Date',
  Options = 'Options',
}

export enum CustomDocumentReviewStatus {
  Accepted = 'accepted',
  Rejected = 'rejected',
  Pending = 'pending',
}

export interface CustomDocumentTemplate {
  caption: string | null;
  isAcceptable: boolean;
  image: Media | null;
}

export interface ICustomDocumentReadingField {
  id: string;
  inputFormat?: string;
  label: string;
  type: CustomDocumentVerificationFlowFieldTypes;
  options?: string[];
}

export interface ICustomDocumentDocumentReading {
  fields?: ICustomDocumentReadingField[];
  images?: Media[];
  instructions?: Nullable<string>;
}

export interface CustomDocumentTemplateMatching {
  instructions?: string | null;
  templates?: CustomDocumentTemplate[];
}

export interface ICustomDocumentFlowVerificationPatterns {
  [VerificationPatternTypes.DocumentReading]?: ICustomDocumentDocumentReading;
  [VerificationPatternTypes.TemplateMatching]?: CustomDocumentTemplateMatching;
}

export interface CustomDocumentResponse {
  type: string | null;
  description: string | null;
  name: string | null;
  pages: number | null;
  status?: CustomDocumentReviewStatus;
  reviewComment?: string;
  isSkippable: boolean;
  isSingleFile: boolean;
  inputValidationChecks: InputValidationCheck[];
  flow: {
    verificationPatterns: ICustomDocumentFlowVerificationPatterns;
  };
  example: {
    [DocumentSideTypes.Front]?: Media | null;
    [DocumentSideTypes.Back]?: Media | null;
  };
}

export type CustomDocumentType = `custom-${string}`;
